import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
// import Menu from "../components/Services/ContentMenu"
import BaseLayout from "../components/global/BaseLayout";
import Busqueda from "../components/Services/Busqueda";

function Services() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Products">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Products"
          Subheader="Products"
          bgimg={`${rpdata?.gallery?.[15]}`}
        />
        {/* <Menu/> */}
        <div className="w-4/5 mx-auto">

          <Busqueda
            phone={rpdata?.dbPrincipal?.phones?.[0]?.phone.replaceAll("-", "")}
            imageDefault={rpdata?.dbPrincipal?.logo}
          />
        </div>

      </div>
    </BaseLayout>
  );
}

export default Services;
